.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .box {
  transform-style: preserve-3d;
  animation: animate 3s ease-out 1;
}

@keyframes animate {
  0% {
    transform: perspective(1000px) rotateX(0deg);
  }
  100% {
    transform: perspective(1000px) rotateX(-720deg);
  }
}

.container .box span {
  font-size: 2em;
  position: absolute;
  background-color: black;
  color: white;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0 10px;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%) rotateX(calc(var(--i) * 22.5deg)) translateZ(90px);
}