
.map-wrapper {
  height: 500px;
  height: 75vh;
}
.map-container {
  height: 100%;
  width: 100%;
}

.bulky-marker-label {
  background-color: white;
  padding: 6px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.3);
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: -20px;
}