.item {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 240px;
}

.selected {
  background-color: #a7ffc3;
}

.not-selected {
  background-color: #ccc;
}

.not-collected {
  background-color: #ffcccc;
}

.collected {
  background-color: #e0ffea;
}

.deleteBtn {
  background-color: rgb(255, 122, 122);
  min-width: 40px;
}

.btnGroup {
  display: inline-flex;
  align-items: center;
}