.wasteCard {
  min-width: 100px;
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
  text-align: center; /* center text inside element */
}

.today {
  font-weight: bold;
}

.tomorrow {
  font-style: bold;
  color: red;
}