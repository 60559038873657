.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

nav {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #282c34;
  color: white;
  padding: 0 50px;
  height: 80px;
}
nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0 20px;
}

.btn {
  padding: 10px;
  margin-left: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
  min-width: 80px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.card {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

select, input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-left: 4px;
}

.inlineForm {
  display: inline-flex;
  align-items: center;
}
